"use client";
import { Box, TextField } from "@mui/material";
import React from "react";
import { setCookie } from "cookies-next";

import { Button, Sections } from "../components";

const login: string = "admin_infuse";
const password: string = "`Xy~8ZF=N3KM£@2TQZ,r#U]5Zc#,Q*";

function DefenderFeature() {
  const [form, setForm] = React.useState<{
    login: string;
    password: string;
  }>({
    login: "",
    password: "",
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    if (form.login === login && form.password === password) {
      const jsonString = JSON.stringify(form);

      const base64Encoded = Buffer.from(jsonString).toString("base64");

      setCookie("custom_token", base64Encoded, {
        maxAge: 60 * 60 * 24 * 7 * 1000,
      });

      window.location.reload();
    } else {
      alert("Invalid credentials");
    }
  };
  return (
    <Sections>
      <Box maxWidth={400} mx="auto">
        <TextField
          placeholder="Login*"
          variant="filled"
          name="login"
          sx={{
            mb: 2,
          }}
          onInput={handleInput}
        />
        <TextField
          placeholder="Password*"
          name="password"
          variant="filled"
          type="password"
          sx={{
            mb: 2,
          }}
          onInput={handleInput}
        />

        <Button onClick={handleSubmit}>Unlock</Button>
      </Box>
    </Sections>
  );
}

export default DefenderFeature;
