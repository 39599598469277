"use client";
import { usePathname, useRouter } from "next/navigation";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { deleteCookie } from "cookies-next";
import { Box, Container, useMediaQuery } from "@mui/material";
import { useAtom } from "jotai";
import { StoreType, userStore } from "@/store/user.store";
import { Icon, Logo } from "@/shared/components";
import { GeneralModal } from "@/shared/modals";
import theme from "@/lib/theme";
import colors from "@/lib/palette";

import Auth from "@/shared/features/Auth";
import SearchFeature from "@/shared/features/search";
import { AuthType } from "@/shared/features/Auth/types";

import HeaderButtons from "./components/header-buttons.component";
import BurgerMenu from "./components/burger-menu.component";
import MobileSearchMenu from "./components/mobile-search-menu.component";

import UserApi from "@/shared/api/user";
import Client from "@/lib/http-client";
import HeaderLoader from "./loader";

const Header = () => {
  const route = usePathname();
  const router = useRouter();

  const [store, setStore] = useAtom<StoreType>(userStore);
  const [isPageReady, setIsPageReady] = useState<boolean>(false);

  const isMdScreen = useMediaQuery<boolean>(theme.breakpoints.up("md"));
  const isHomePage = useMemo<boolean>(() => route === "/", [route]);
  const isProfilePage = useMemo<boolean>(
    () => route.includes("/profile"),
    [route]
  );

  const [fixedHeader, setFixedHeader] = useState<boolean>(false);
  const [openBurgerMenu, setOpenBurgerMenu] = useState<boolean>(false);
  const [openSearchMenu, setOpenSearchMenu] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [authType, setAuthType] = useState<AuthType>("signIn");

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenSingUpModal = () => {
    setAuthType("signUp");
    handleOpenModal();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setAuthType("signIn");
  };

  const handleCloseBurgerMenu = () => {
    setOpenBurgerMenu(false);
    setOpenSearchMenu(false);
  };

  const onScrollDocument = () => {
    if (window.scrollY > 100) {
      setFixedHeader(true);
    } else {
      setFixedHeader(false);
    }
  };

  const handleLogout = async () => {
    try {
      if (isProfilePage) {
        router.push("/");
      }

      await UserApi.logout();

      const timeout = setTimeout(() => {
        Client.setAccessResource("");
        Client.setAccessToken("");

        setStore({ isAuth: false, user: null });

        deleteCookie("token", {
          expires: new Date(Date.now() - 1),
        });
        deleteCookie("x-access-resource", {
          expires: new Date(Date.now() - 1),
        });

        toast.success("Logged out successfully", {
          icon: () => <Icon icon="success-message" width={32} height={32} />,
        });

        clearTimeout(timeout);
      }, 1000);
    } catch (error) {
      toast.error("Error logging out", {
        icon: () => <Icon icon="error-message" width={32} height={32} />,
      });
    }
  };

  useEffect(() => {
    if (typeof window === "undefined") return;

    window?.addEventListener("scroll", onScrollDocument);
  }, []);

  useEffect(() => {
    const handlePageLoad = () => {
      setIsPageReady(true);
    };

    if (document.readyState === "complete") {
      handlePageLoad();
    } else {
      window.addEventListener("load", handlePageLoad);
      return () => window.removeEventListener("load", handlePageLoad);
    }
  }, []);

  if (!isPageReady) {
    return <HeaderLoader />;
  }

  return (
    <>
      <Box
        component="header"
        position={fixedHeader || openBurgerMenu ? "fixed" : "absolute"}
        bgcolor={fixedHeader || openBurgerMenu ? colors.white : "transparent"}
        boxShadow={
          fixedHeader || openBurgerMenu
            ? "0px 4px 4px rgba(0, 0, 0, 0.05)"
            : "none"
        }
        sx={{
          width: "100%",
          zIndex: 100,
          top: 0,
        }}
      >
        <Container sx={{ py: "20px" }}>
          <Box display="flex" alignItems="center">
            <Logo />

            {isMdScreen && !isHomePage && (
              <SearchFeature
                variant="secondary"
                sx={{
                  flex: 1,
                  mx: {
                    xs: 3,
                    xl: 4,
                  },
                }}
              />
            )}

            <HeaderButtons
              store={store}
              openBurgerMenu={openBurgerMenu}
              onOpenSearchMenu={() => setOpenSearchMenu(true)}
              onToggleMenu={() => setOpenBurgerMenu(!openBurgerMenu)}
              onOpenModal={handleOpenModal}
              onLogout={handleLogout}
            />
          </Box>
        </Container>

        <GeneralModal open={openModal} handleClose={handleCloseModal}>
          <Auth
            authType={authType}
            handleClose={handleCloseModal}
            setAuthType={setAuthType}
          />
        </GeneralModal>
      </Box>

      {!isMdScreen ? (
        <>
          <BurgerMenu
            open={openBurgerMenu}
            isAuth={store.isAuth}
            onOpenLoginModal={handleOpenModal}
            onOpenSingUpModal={handleOpenSingUpModal}
            onClose={handleCloseBurgerMenu}
            onLogout={handleLogout}
          />

          <MobileSearchMenu
            open={openSearchMenu}
            onClose={() => setOpenSearchMenu(false)}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Header;
