import { useState } from "react";

import { Box, Typography } from "@mui/material";
import { toast } from "react-toastify";

import { Button, Icon } from "@/shared/components";
import { AuthType } from "../types";
import AuthApiData from "../api/auth.api";

type Props = {
  title: string;
  email: string;
  setEmail: (email: string) => void;
  setAuthType: (authType: AuthType) => void;
};

function EmailAlreadyRegistrated(props: Props) {
  const { email, title, setAuthType } = props;

  const [loading, setLoading] = useState(false);

  const handleResendOtpCode = async () => {
    setLoading(true);
    try {
      await AuthApiData.resetOtpCode({
        email,
        action: "profile_activation",
      });

      toast.success("Confirmation code has been sent to your email", {
        icon: () => <Icon icon="success-message" width={32} height={32} />,
      });

      setAuthType("signUpConfirmation");
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h2" textAlign="center">
        {title}
      </Typography>

      <Typography
        variant="body2"
        textAlign="center"
        marginBottom={4}
        marginTop={2}
      >
        This <b>{email}</b> email is already registered in our system.
        <br />
        You can resend the confirmation code to this email and activate your
        account.
      </Typography>

      <Button
        variant="filled"
        disabled={loading}
        loading={loading}
        sx={{ marginTop: 1 }}
        onClick={handleResendOtpCode}
      >
        Resend confirmation code
      </Button>

      <Typography
        variant="body2"
        textAlign="center"
        marginBottom={1}
        marginTop={1}
      >
        or
      </Typography>

      <Button
        variant="outlined-primary"
        disabled={loading}
        loading={loading}
        sx={{ marginTop: 1 }}
        onClick={() => setAuthType("signUp")}
      >
        Create new account
      </Button>
    </Box>
  );
}

export default EmailAlreadyRegistrated;
